import consumer from "./consumer"

consumer.subscriptions.create({ channel: "GotoChannel" }, {
  received(data) {
    console.log("goto channel: received")
    //this.appendLine(data)
    logCall(data)
  },

  appendLine(data) {
    const html = this.createLine(data)
    const element = document.querySelector("#gotoTest")
    element.insertAdjacentHTML("beforeend", html)
  },

  createLine(data) {
    return `<div>${data["ext"]}: ${data["name"]} (${data["caller_id"]})</div>`
  }
})
